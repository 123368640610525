<template>
  <div id="ProducerShowsComparison">
    <card
      v-for="{
        cardStyle,
        showListQlikId,
        datesListQlikId,
        alternateStateName
      } in comparisonFilters"
      :style="cardStyle"
      :key="alternateStateName"
    >
      <div style="display: flex; height: 90%">
        <qs-object
          :qlik-id="showListQlikId"
          style="flex-grow: 1.5"
          @click.native="$mixpanel.track('Click show selector box')"
        />
        <qs-object :qlik-id="datesListQlikId" style="flex-grow: 1" />
      </div>
      <a
        class="a-button cursor-pointer"
        @click="clearState(alternateStateName)"
        style="height: 10%; width: 100%"
      >
        <fa :icon="['far', 'trash']" />
      </a>
    </card>

    <QsTabs :qlik-ids="['zEWGp', 'BYpp']" style="grid-column: 1 / span 24" />
  </div>
</template>

<script>
import Card from "@/components/Card";
import QsTabs from "@/components/Qlik/QsTabs";
import QsObject from "@/components/Qlik/QsObject";
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("qlik");

export default {
  components: { QsObject, QsTabs, Card },
  props: { field: String, icon: String },
  computed: {
    ...mapState(["app"]),
    comparisonFilters: () => [
      {
        cardStyle: "grid-column: 1 / span 6",
        showListQlikId: "jnamkXt",
        datesListQlikId: "drtNPP",
        alternateStateName: "show_comparison_1"
      },
      {
        cardStyle: "grid-column: 7 / span 6",
        showListQlikId: "mLhCuJH",
        datesListQlikId: "RwhJrD",
        alternateStateName: "show_comparison_2"
      },
      {
        cardStyle: "grid-column: 13 / span 6",
        showListQlikId: "TjNuD",
        datesListQlikId: "AURAdn",
        alternateStateName: "show_comparison_3"
      },
      {
        cardStyle: "grid-column: 19 / span 6",
        showListQlikId: "tfDrdd",
        datesListQlikId: "EkmJVU",
        alternateStateName: "show_comparison_4"
      }
    ]
  },
  methods: {
    clearState(state) {
      this.app.clearAll(false, state);
      document.activeElement.blur();
    }
  }
};
</script>

<style scoped>
#ProducerShowsComparison {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: 200px 450px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>
